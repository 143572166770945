import Button from 'epn-ui/umd/dist/components/Button';

// Helpers
import useI18n from '@i18n';

// HOCs
import WithRole, { rolesEnum } from '@HOCs/WithRole';

/**
 * При переходе на SSR данный файл нужно переименовать в _error.tsx и прокинуть statusCode
 * Документация: https://nextjs.org/docs/advanced-features/custom-error-page#more-advanced-error-page-customizing
 */
const ErrorPage: React.FC = () => {
    const { t } = useI18n();

    return (
        <div className="errorPage">
            <div className="errorPage__codeBg">404</div>
            <div className="errorPage__content">
                <div className="errorPage__code">404</div>
                <div className="errorPage__text">{t('Error 404')}</div>
                <div className="errorPage__action">
                    <Button.Beta type="ghost" size="large" href="/">
                        {t('Come back home')}
                    </Button.Beta>
                </div>
            </div>
        </div>
    );
};

export default WithRole(ErrorPage, [rolesEnum.guest]);
